<template>
	<PageLayout :title="$t('deposit.title')">
		<div id="cashierWrapper" />
		<div v-if="!cashierToken">
			<div class="cashier-box cashier-box--deposit">
				<payment-logo :custom-logo="'cards_crypto.svg'" />
				<div class="small-width overflow-visible">
					<payment-wallet />
				</div>

				<div class="line-separator-wrapper">
					<div class="line-separator" />
				</div>

				<amount-buttons
					:buttons-value="currentMethod.predefinedDepositAmounts"
					:form-amount-value="form.amount || currentMethod.predefinedDepositAmounts[0]"
					:currency="'USD'"
					:form-errors="formErrors['amount']"
					:current-method="currentMethod"
					@set-button-value="setButtonValue"
					@input-amount-value-validation="setFormValidation"
				/>
				<span class="amount-error">{{ formErrors.amount }}</span>
				<div class="small-width">
					<promocode
						:form-promo-value="form.promocode"
						:form-error="formErrors['promocode']"
						@set-promo-value="setPromoValue"
					/>
				</div>

				<button type="button" class="button primary-button deposit center" :disabled="!form.validated" @click="pay()">
					{{ $t('deposit.top-up') }}
				</button>

				<div v-if="loading" class="loader-wrapper">
					<loader />
				</div>
			</div>
			<div class="method-description-cashier">
				{{ $t(`deposit.method-descriptions.brcards`) }}
			</div>
		</div>
	</PageLayout>
</template>
<script>
import PageLayout from '@/components/page-layout/PageLayout';
import apiClient from '../../../../api';
import Analytics from '@/utils/analytics';

const loader = () => import('../../../../shared/loader/Loader.vue');
const paymentLogo = () => import('../../../../shared/payment-logo/PaymentLogo');
const amountButtons = () => import('../../../../shared/amount-buttons/AmountButtons');
const promocode = () => import('../../../../shared/promocode/Promocode');
const paymentWallet = () => import('../../../../shared/payment-wallet/PaymentWallet');

export default {
	name: 'DepositBridgerpayCC',
	components: {
		PageLayout,
		loader,
		paymentLogo,
		amountButtons,
		promocode,
		paymentWallet,
	},
	data() {
		return {
			cashierUrl: null,
			cashierKey: null,
			cashierToken: null,
			form: {
				amount: null,
				promocode: null,
				validated: true,
			},
			formErrors: {
				amount: null,
				promocode: null,
			},
			loading: false,
			paymentDone: false,
			walletId: null,
		};
	},
	computed: {
		currentMethod() {
			return this.$store.getters.getCurrentMethod('brcards');
		},
	},
	async mounted() {
		await this.getUserWallet();
	},
	methods: {
		setButtonValue(event) {
			this.form.amount = event;
		},
		setPromoValue(event) {
			this.form.promocode = event;
		},
		setFormValidation(event) {
			this.form.validated = event;
		},
		async getUserWallet() {
			try {
				const { id } = await apiClient.fetchFullWallet(this.$route.params.walletId);
				this.walletId = id;
			} catch (e) {
				this.error = 'wallets.failed-to-load-wallet';
			} finally {
				this.loading = false;
			}
		},
		async createTransaction(amount, currency, promocode) {
			const response = await apiClient.bridgerpayCreateTransaction(amount, currency, this.walletId, promocode);
			if (response.status === 201) {
				return response.data;
			}
			throw new Error('Invalid response from create transaction');
		},
		async pay() {
			this.loading = true;

			try {
				const currency = 'USD';
				const transaction = await this.createTransaction(this.form.amount, currency, this.form.promocode);

				Analytics.trackEvent({
					event: 'Deposit',
					value: this.form.amount,
					label: 'BridgerpayCreditCard',
				});

				this.cashierUrl = transaction.cashierUrl;
				this.cashierKey = transaction.cashierKey;
				this.cashierToken = transaction.cashierToken;

				const tag = document.createElement('script');
				tag.setAttribute('src', this.cashierUrl);
				tag.setAttribute('data-cashier-key', this.cashierKey);
				tag.setAttribute('data-cashier-token', this.cashierToken);
				const wrapper = document.getElementById('cashierWrapper');
				wrapper.appendChild(tag);
			} catch (e) {
				console.error(e);
				if (e.response?.status === 422) {
					const { errors } = e.response.data;
					errors.forEach(({ param, msg }) => {
						this.formErrors[param] = msg;
					});
					return;
				}

				const walletId = this.$route.params.walletId;
				this.$router.push({
					path: `/payments/deposit/${walletId}/confirmation`,
					query: { module: 'Deposit', status: 'error' },
				});
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
